import React from 'react';
import styled, { keyframes } from "styled-components";

const bouncingAnimation = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-8px);
  }
`;

const BouncingContainer = styled.span`
  display: flex;
  justify-content: center;
`;

const BouncingIcon = styled.svg`
  margin: 0 4px;
  border-radius: 50%;
  opacity: 1;
  animation: ${bouncingAnimation} 0.6s infinite alternate;
  animation-delay: ${({ delay }) => delay || "0s"};
`;

const TripleDotAnimation = ({
  size = "0.5em",
  color = "currentColor",
  className,
  ...rest
}) => {
  return (
    <BouncingContainer className={className} {...rest}>
      <BouncingIcon width={size} height={size} viewBox="0 0 24 24" fill="none" delay="0s">
        <circle cx="12" cy="12" r="12" fill={color} />
      </BouncingIcon>
      <BouncingIcon width={size} height={size} viewBox="0 0 24 24" fill="none" delay="0.2s">
        <circle cx="12" cy="12" r="12" fill={color} />
      </BouncingIcon>
      <BouncingIcon width={size} height={size} viewBox="0 0 24 24" fill="none" delay="0.4s">
        <circle cx="12" cy="12" r="12" fill={color} />
      </BouncingIcon>
    </BouncingContainer>
  );
};

export default TripleDotAnimation;
