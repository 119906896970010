/**
 * Colors
 */
const colors = {
  white: '#fff',
  black: '#000',
  primary: {
    _000: '#420080', //F6871F usado no pendente resposta
    _100: '#27004D',
    _200: '#1A0033',
  },

  secondary: '#13BF1B',

  purple: {
    _000: '#D3C6Df',
    _100: '#AF94C7',
    _200: '#8A63B0',
    _300: '#663198',
    _400: '#420080',
    _500: '#350066', // cor do hover
    _600: '#27004D',
    _700: '#1A0033'
  },

  green: {
    _000: '#C6F7CB',
    _100: '#94F7A0',
    _200: '#63F77D',
    _300: '#31F749',
    _400: '#00DE1A',
    _500: '#13BF1B',
    _600: '#279F1D',
    _700: '#3A801E'
  },

  cyan: {
    _000: '#CAF1F1',
    _100: '#9DEBEC',
    _200: '#71E5E6',
    _300: '#44DFE1',
    _400: '#17D9DB',
    _500: '#16BBCB',
    _600: '#159EBB',
    _700: '#1580AB'
  },

  orange: {
    _000: '#F9E2C6',
    _100: '#FACD94',
    _200: '#FCB963',
    _300: '#FDA431',
    _400: '#FF8F00',
    _500: '#D77200',
    _600: '#B05600',
    _700: '#883900'
  },

  gray: {
    _000: '#FFFFFF',
    _100: '#F9F9F9',
    _200: '#E9EEF3',
    _300: '#E0E5EE',
    _400: '#B6BACD',
    _500: '#7F859D',
    _600: '#414251',
    _700: '#c5c5c5'
  },

  red: {
    __700: "#D74B29",
  },

  status: {

    // colocar o #F6871F aqui para o pendente resposta e tela de workflow
    // 2B5D7E na tela do workflow
    // '#0076DE', // cor na tela do workflow 0076DE
    // '#2B5D7E', // cor na tela do workflow
    error: '#D74B29',
    success: '#00A28F', // cor ta tela do workflow
    warning: '#FFB900',
    warning_100: '#F6871F',
    info_000: '#0076DE',
    info_100: '#2B5D7E',
    automatic_approval: '#007262'
  },

  risks: {  
    low: '#00A28F',
    medium: '#F6871F',
    high: '#D74B29',
    critical: '#951F02',
  },

  support: {
    _000: '#3E7898',
    _100: '#2B5D7E', // cor na tela do workflow
    _200: '#CCEBFF',
    _300: '#0076DE', // cor na tela do workflow 0076DE
  },

  chart: {
    _100: '#649EFF',
    _200: '#6FD4FF',
  },
};

export default colors;
