import styled from "styled-components";

import { H6 } from "~/components";
import { colors, typograph } from "~/styles";
const { gray, red } = colors;
const { weight, size } = typograph;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InfoText = styled.span`
  color: ${gray._500};
  font-size: ${size.s3};
  line-height: ${size.m1};
`;

export const ErrorWarning = styled.div`
  & span {
    color: ${props => (props.hasError ? red.__700 : gray._600)} !important;
  }
  & > div > div > input {
    color: ${props => (props.hasError ? red.__700 : gray._600)} !important;
  }
`;

export const ModalView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > ${H6} {
    font-size: ${size.m1};
    font-weight: ${weight.semiBold};
    color: ${gray._600};
    margin-bottom: 1.2rem;
    text-align: left !important;
  }
  
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 16rem;

    & > div {
      & > span {
        color: ${gray._600};
        line-height:2.5rem;
        font-size: 12px;
      }
      & > div {
        width: 8.5rem;
        input {
          margin-top: 0.5rem;
          height: 4rem;
        }
      }
    }

    & > p {
      color: ${gray._600};
      line-height:2.5rem;
    }
  }

  form {
    display: flex;
    flex: 1;

    & > div {
      margin-top: 2.4rem;
    }
  }
  &.modal-sm {
    min-height: 20rem;
  }
  &.modal-md {
    min-height: 26rem;
  }
  &.modal-lg {
    min-height: 32rem;
  }
`;
