import React, { useState } from "react";
import { useAlert } from "react-alert";

import { Button } from "~/components";
import { useMe, error } from "~/services";
import { formatErrorMessage, useApi, validate } from "~/hooks";

import { Diligence } from "../../Diligence";
import { useSearch } from "../../../context/SearchProvider";

import { Search, ErrorsWrapper } from "../../styles";

import { useValidator } from "./validator";

export const DiligenceHeader = () => {
  const api = useApi();
  const alert = useAlert();
  const { user, group } = useMe();
  const { schema } = useValidator();

  const {
    t,
    ref,
    tag,
    show,
    query,
    tAuth,
    onShow,
    person,
    dPerson,
    loading,
    checked,
    onPerson,
    workflow,
    onLoading,
    dDocument,
    setChecked,
    fields_risk,
    arr_workflows,
    render_process,
    jurisdictionValue,
    arr_person_workflows,
    arr_entity_workflows,
    setOpenShowDiligenceForceModal,
    forceNoLimitAssociatedAssessments,
    setLastSameAssessment,
  } = useSearch();

  const [formattedDataState, setFormattedData] = useState();

  const disableButton =
    !workflow || workflow?.length <= 0 || !person || person?.length <= 0;

  const resetForm = () => {
    onPerson("");
    ref.current.setFieldValue("document", "");
    ref.current.setFieldValue("workflows", []);
    ref.current.setFieldValue("personType", "");
    ref.current.setFieldValue("jurisdiction", null);
    ref.current.setFieldValue("personWorkflows", []);
    ref.current.setFieldValue("entityWorkflows", []);
    ref.current.setFieldValue("name_jurisdiction", "");
  };

  const submit_diligence = async (data, type, force = false) => {
    let allIsFilled;
    let formattedData;
    const handle = () => onShow(!show);

    if (!force) {
      const uniqueRisks = data?.risks?.reduce((acc, curr) => {
        const repeated = acc.find(
          (item) =>
            item.id === curr.id && item.risk_source_id === curr.risk_source_id
        );
        if (!repeated) {
          return acc.concat([curr]);
        }
        return acc;
      }, []);

      if (data.prepaid_receiver) {
        setChecked(false);
      }

      formattedData = {
        ...data,
        document:
          person?.value === 1 || person?.value === 2
            ? data.document
            : undefined,
        risks: uniqueRisks,
        userId: String(user.usuario.id),
        clientId: group.value === 0 ? null : String(group.value),
        workflows: arr_workflows,
        countryId: "76",
        personType: person.value || null,
        forceOpen: false,
        jurisdiction:
          person?.value === 3 || person?.value === 4
            ? jurisdictionValue
            : undefined,
        name:
          person?.value === 3 || person?.value === 4
            ? data.name_jurisdiction
            : undefined,
        partnerSearch: checked,

        personWorkflows: arr_person_workflows,
        entityWorkflows: arr_entity_workflows,
        force_no_limit_associated_assessments:
          forceNoLimitAssociatedAssessments ?? undefined,
      };

      if (tag) {
        formattedData = { ...formattedData, tagId: tag.value };
      }

      setFormattedData(formattedData);

      const filledFieldsNumber =
        data?.risks?.reduce(
          (acc, risk) => acc + Number(risk.mandatory !== 0),
          0
        ) || 0;

      allIsFilled = filledFieldsNumber === (data?.risks?.length || 0);
    } else {
      allIsFilled = true;
      formattedData = formattedDataState;
      formattedData.forceOpen = true;
    }

    const created = async () => {
      if (allIsFilled) {
        schema
          .validate(formattedData, { abortEarly: false })
          .then(async () => {
            onLoading(true);
            await api()
              .post("/process/store", formattedData)
              .then(async (res) => {
                if (res.data.type === "fail") {
                  alert.error(res.data.data);
                } else {
                  alert.success(t("evaluation.alerts.success"));

                  if (tag?.value) {
                    await api().post("/process/tag/associate", {
                      tagId: tag.value,
                      processId: res.data.id,
                    });
                  }

                  onShow(false);
                  await render_process();
                  resetForm();
                  setFormattedData(null);
                  onLoading(false);
                  setChecked(false);
                }
              })
              .catch(({ response }) => {                
                onLoading(false);

                if (
                  response.status === 400 &&
                  response.data.message === t("evaluation.alerts.similar_process")
                ) {
                  setOpenShowDiligenceForceModal(true);
                  setLastSameAssessment(response.data.processes);
                } else {
                  alert.error(
                    formatErrorMessage(
                      response,
                      t("evaluation.alerts.error"),
                      tAuth("expired")
                    )
                  );
                }
              });
          })
          .catch((err) => {
            const element = (
              <ErrorsWrapper>
                {Object.keys(err.inner).map((key) => (
                  <p>{err.inner[key].message}</p>
                ))}
              </ErrorsWrapper>
            );

            alert.error(element);
            error(err, ref);
            onLoading(false);
          });
      } else {
        alert.error(t("evaluation.alerts.filled"));
      }
    };

    switch (type) {
      case "show":
        handle();
        if (allIsFilled && !fields_risk?.length) {
          created();
        }
        break;
      case "forceOpen":
        handle();
        if (allIsFilled) {
          created();
        }
        break;

      case "create":
        created();
        break;

      default:
        break;
    }
  };

  return (
    <Search
      ref={ref}
      type={query}
      onSubmit={(val) => submit_diligence(val, show ? "create" : "show")}
      initialData={
        dPerson && {
          document: dDocument,
        }
      }
    >
      <Diligence submitDiligence={submit_diligence} />
      <Button
        submit
        size="sm"
        loading={loading}
        appearance="secondary"
        disabled={disableButton}
        label={t("components.start")}
      />
    </Search>
  );
};
