import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from "react";

import { element } from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "~/hooks";
import { useMe } from "~/services";
import useWebSocketRefresh from "~/hooks/useWebSocketRefresh";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const { t } = useTranslation("search");
  const { t: tC } = useTranslation("card");
  const { t: tAuth } = useTranslation("auth");
  const { t: tEntity } = useTranslation("entities");
  const { t: tComponents } = useTranslation("components");

  const ref = useRef();
  const uploadRef = useRef();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { user, group, newGroup } = useMe();

  const [tag, setTag] = useState();
  const [person, onPerson] = useState();
  const [show, onShow] = useState(false);
  const [modal, onModal] = useState(false);
  const [workflow, onWorkflow] = useState();
  const [loading, onLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [personWorkflow, onPersonWorkflow] = useState();
  const [entityWorkflow, onEntityWorkflow] = useState();
  const [checkPrepaid, setCheckPrepaid] = useState(true);
  const [jurisdictionValue, onJurisdiction] = useState(null);
  const [loadingBatches, onLoadingBatches] = useState(false);
  const [attechmentFile, setAttechmentFile] = useState(null);
  const [showPrePaidMenu, setShowPrePaidMenu] = useState(false);
  const [showAttechmentModal, setShowAttechmentModal] = useState(false);
  const [showOpenDiligenceForceModal, setOpenShowDiligenceForceModal] =
    useState(false);
  const [lastSameAssessment,setLastSameAssessment] = useState(null);

  const [
    forceNoLimitAssociatedAssessments,
    setForceNoLimitAssociatedAssessments,
  ] = useState(false);

  const params = new URLSearchParams(search);

  const mutations = [];

  const arr_person_workflows = personWorkflow?.value
    ? [personWorkflow.value]
    : [];
  const arr_entity_workflows = entityWorkflow?.value
    ? [entityWorkflow.value]
    : [];

  const arr_workflows = workflow?.value ? [workflow.value] : [];

  const dPerson = params.get("person");
  const dDocument = params.get("document");
  const query = params.get("q") || 'diligencias';

  const { data: relationships, isValidating: isLoadingRelationShips } =
    useFetch({
      url: `/relationships?${
        group.value === 0 ? "" : `clientId=${group.value}&`
      }`,
    });

  const { data: rows } = useFetch({ url: "/simplesearch" });

  const { data: tags, mutate: render_tag } = useFetch({
    url: `/tag?${group.value === 0 ? "" : `clientId=${group.value}`}`,
  });
  const { data: risksData } = useFetch({ url: "/risksource" });
  const { data: clients } = useFetch({
    url: `/entities/liveSearch?search=${document}`,
  });
  const { data: persons } = useFetch({ url: "/clients/getPersonTypes" });

  const {
    data: processes,
    mutate: render_process,
    isValidating: isLoadingProcess,
  } = useFetch({
    url: `/process?${group.value === 0 ? "" : `clientId=${group.value}&`}`,
    mutations,
  });
  const {
    data: batches,
    mutate: renderBatches,
    isValidating: isLoadingBatches,
  } = useFetch({
    url:
      group.value !== "0"
        ? `/batches?id=${group.value}&newDD=true`
        : `/batches?newDD=true`,
  });
  const { data: workflows } = useFetch({
    url: `/clients/getContent?${
      group.value === 0 ? "" : `clientId=${group.value}&`
    }${
      person ? `workflowType=${person.value}&&` : ""
    }notBasic=1&content=workflows`,
  });
  const { data: personWorkflows } = useFetch({
    url: `/clients/getContent?${
      group.value === 0 ? "" : `clientId=${group.value}&`
    }${
      person ? `workflowType=${1}&notBasic=0&` : ""
    }content=workflows&order=true`,
  });
  const { data: entityWorkflows } = useFetch({
    url: `/clients/getContent?${
      group.value === 0 ? "" : `clientId=${group.value}&`
    }${
      person ? `workflowType=${2}&notBasic=0&` : ""
    }content=workflows&order=true`,
  });

  const { data: fields_risk } = useFetch({
    url:
      arr_workflows?.length > 0
        ? `/clients/getContent?${
            group.value === 0 ? "" : `clientId=${group.value}&`
          }${
            arr_workflows?.length > 0 ? `workflowId=[${arr_workflows}]&` : ""
          }${
            person ? `workflowType=${person.value}&` : ""
          }content=campos_fonte_risco`
        : null,
  });

  const { data: jurisdiction } = useFetch({ url: "/jurisdiction" });

  const hasJurisdiction = person && person?.value !== 1 && person?.value !== 2;

  const risks = useMemo(() => {
    if (risksData) {
      if (query === "simplificada") {
        const simpleSearchRisks = risksData.filter(
          (risk) => risk.simple_search === 1
        );
        const notOptionalRisks = simpleSearchRisks.filter(
          (risk) => !risk.simple_search_optional
        );
        const optionalRisks = simpleSearchRisks.filter(
          (risk) => !!risk.simple_search_optional
        );
        return [...notOptionalRisks, ...optionalRisks];
      }
      return risksData;
    }

    return [];
  }, [query, risksData]);

  const options = useMemo(
    () => ({
      tags: tags?.data?.map((item) => ({
        label: [item.name],
        value: item.id,
      })),
      group: user.hierarquia?.map((item) => ({
        value: item.id,
        label: item.cliente,
      })),
      person: persons?.map((item) => ({
        value: item.id,
        label: item.description,
      })),
      clients: clients
        ?.filter((item) => item.tipo === "company")
        ?.map((item) => ({
          label: item.name,
          value: item.document,
        })),
      allRisks: risks
        ?.filter((risk) => risk.person_type === person?.value)
        ?.map((item) => ({
          value: item.id,
          label: item.name,
          isDisabled: !item.simple_search_optional,
        })),
      workflows: workflows
        ?.filter((item) => item.active !== 0)
        .map((item) => ({
          value: item.id,
          label: item.name,
        })),
      jurisdiction: jurisdiction?.data.map((item) => ({
        value: item.id,
        label: `${item.a2code} - ${item.name}`,
      })),
      personWorkflows: personWorkflows
        ?.filter((item) => item.active !== 0)
        .map((item) => ({
          value: item.id,
          label: item.name,
        })),
      entityWorkflows: entityWorkflows
        ?.filter((item) => item.active !== 0)
        .map((item) => ({
          value: item.id,
          label: item.name,
        })),
    }),
    [
      clients,
      jurisdiction?.data,
      person?.value,
      persons,
      risks,
      t,
      tags?.data,
      user.hierarquia,
      workflows,
      personWorkflows,
      entityWorkflows,
    ]
  );

  const filtered = useCallback(() => {
    const filter = fields_risk?.reduce((acc, curr) => {
      const { workflow_id, workflow_name, ...rest } = curr;

      acc[curr.workflow_id] = {
        workflow_id,
        workflow_name,
        items: [],
        ...acc[workflow_id],
      };
      acc[curr.workflow_id].items.push(rest);

      return acc;
    }, {});

    const items = [];

    // eslint-disable-next-line
    for (const index in filter) {
      items.push(filter[index]);
    }
    return items;
  }, [fields_risk]);

  const mask = {
    1: "999.999.999-99",
    2: "99.999.999/9999-99",
    3: "99.999.999/9999-99",
    4: "99.999.999/9999-99",
  };

  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }

  useWebSocketRefresh(
    {
      channel: "App.Events.ProcessStatusUpdated",
      events: processes?.data?.map(
        (process) => `App\\Events\\ProcessStatusUpdated\\${process.id}`
      ),
      mutations,
    },
    [processes]
  );

  useEffect(() => {
    if (checkPrepaid) {
      setChecked(false);
      onPersonWorkflow([]);
      onEntityWorkflow([]);
    }
  }, [checkPrepaid]);

  useEffect(() => {
    if (!params.get("q")) {
      user?.isVisualizador()
        ? navigate("/resultados?q=diligencia")
        : navigate("/pesquisa?q=diligencia")
    }
  }, [params, navigate]);

  useEffect(() => {
    if (query === "diligencia" && dPerson) {
      onPerson({
        value: Number(dPerson),
        label: dPerson === "1" ? t("person_types.0") : t("person_types.1"),
      });
    }
  }, [query, dPerson, t]);

  useEffect(() => {
    if (!dPerson) onPerson(null);
  }, [query, dPerson]);

  useEffect(() => {
    if (query === "simplificada" && !loading) {
      ref.current.setFieldValue("riskSource", options.allRisks);
    }
  }, [query, options, loading]);

  useEffect(() => {
    const client = user.hierarquia?.find(({ id }) => id === group.value);
    const settingObject = client?.settings?.find(
      ({ type }) => type === "PREPAID_DILIGENCE"
    );
    const prepaidSetting = JSON.parse(
      settingObject?.data || '{"value": false}'
    ).value;
    setShowPrePaidMenu(!!prepaidSetting);
  }, [group]);

  useEffect(() => {
    setTag(null);
    onWorkflow(null);
    onPerson(null);
    setChecked(null);
    // ref.current.setFieldValue("tag", null);
    // ref.current.setFieldValue("workflow", null);
    // ref.current.setFieldValue("workflows", null);
    // ref.current.setFieldValue("jurisdiction", null);
  }, [group, query]);

  useEffect(() => {
    if (person) onShow(false);
  }, [person]);

  useEffect(() => {
    attechmentFile && onLoadingBatches(false);
  }, [attechmentFile]);

  const value = useMemo(
    () => ({
      t,
      tC,
      ref,
      tag,
      mask,
      modal,
      rows,
      tags,
      tAuth,
      query,
      show,
      risks,
      setTag,
      onShow,
      person,
      options,
      tEntity,
      persons,
      onModal,
      checked,
      clients,
      batches,
      loading,
      dPerson,
      workflow,
      filtered,
      onPerson,
      dDocument,
      uploadRef,
      onLoading,
      workflows,
      risksData,
      processes,
      onWorkflow,
      setChecked,
      render_tag,
      tComponents,
      fields_risk,
      jurisdiction,
      arr_workflows,
      relationships,
      renderBatches,
      attechmentFile,
      loadingBatches,
      entityWorkflow,
      render_process,
      personWorkflow,
      onJurisdiction,
      showPrePaidMenu,
      setCheckPrepaid,
      hasJurisdiction,
      entityWorkflows,
      personWorkflows,
      onLoadingBatches,
      onPersonWorkflow,
      onEntityWorkflow,
      isLoadingProcess,
      isLoadingBatches,
      jurisdictionValue,
      setAttechmentFile,
      showAttechmentModal,
      arr_person_workflows,
      arr_entity_workflows,
      isLoadingRelationShips,
      setShowAttechmentModal,
      showOpenDiligenceForceModal,
      setOpenShowDiligenceForceModal,
      forceNoLimitAssociatedAssessments,
      setForceNoLimitAssociatedAssessments,
      lastSameAssessment,
      setLastSameAssessment,
    }),
    [
      t,
      tC,
      ref,
      tag,
      mask,
      modal,
      rows,
      tags,
      tAuth,
      query,
      show,
      risks,
      person,
      options,
      tEntity,
      persons,
      checked,
      clients,
      batches,
      loading,
      dPerson,
      workflow,
      filtered,
      uploadRef,
      workflows,
      risksData,
      processes,
      dDocument,
      render_tag,
      tComponents,
      fields_risk,
      jurisdiction,
      relationships,
      arr_workflows,
      renderBatches,
      entityWorkflow,
      loadingBatches,
      render_process,
      attechmentFile,
      personWorkflow,
      personWorkflows,
      entityWorkflows,
      hasJurisdiction,
      isLoadingProcess,
      isLoadingBatches,
      jurisdictionValue,
      showAttechmentModal,
      arr_entity_workflows,
      arr_person_workflows,
      isLoadingRelationShips,
      showOpenDiligenceForceModal,
      forceNoLimitAssociatedAssessments,
      lastSameAssessment,
      setLastSameAssessment,
    ]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);

SearchProvider.propTypes = {
  children: element.isRequired,
};
