import React, {useCallback} from "react";

import { parseISO as dateFnsParseISO } from "date-fns";

import { Tooltip, P2 } from "~/components";
import { api } from "~/services";
import { useTranslation } from "react-i18next";


/**
 * Reduce text.
 */
export const reduce = (text, length) =>
  text && text.length && text.length > length
    ? `${text.substring(0, length)}...`
    : text;

/**
 * Rgb color.
 */
const hexToRgb = (hex) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const value = hex.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

export const rgba = (hex, alpha) => {
  const color = hexToRgb(hex);
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

/**
 * Mask cpf and cnpj.
 */
const cpf = (val) =>
  val
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

const cnpj = (val) =>
  val
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");

export const numberMask = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const masked = (val) => {
  if (val.replace(/\D/g, "").length === 11) {
    return cpf(val);
  }

  if (val.replace(/\D/g, "").length === 14) {
    return cnpj(val);
  }

  return val;
};

/**
 * Mask Money (USD and BRL).
 */

export const maskMoney = (val, currency = "BRL") => {
  const brMoney = val.toLocaleString("pt", {
    style: "currency",
    currency: "BRL",
  });
  const usMoney = val.toLocaleString("us", {
    style: "currency",
    currency: "USD",
  });

  return currency === "BRL" ? brMoney : usMoney;
};

/**
 * Take all values
 * and remove duplicates
 */
export const isChecked = (value, filter) => filter?.indexOf(value) !== -1;

export const onChecked = (value, filter, onFilter, prop) => {
  const index = filter.indexOf(value);

  if (index === -1) {
    onFilter([...filter, value], prop);

    return;
  }

  onFilter(
    filter.filter((item, i) => i !== index),
    prop
  );
};

export const removeFalsies = (array) => array.filter((value) => !!value);

export const convertPercentageToRiskLevel = (percentage) => {
  let riskLevel = percentage < 25 ? 1 : 0;
  riskLevel = percentage >= 25 && percentage < 40 ? 2 : riskLevel;
  riskLevel = percentage >= 40 && percentage < 70 ? 3 : riskLevel;
  riskLevel = percentage >= 70 ? 4 : riskLevel;
  return riskLevel;
};

export const capitalizeFirstLetters = (string) =>
  string
    .split(" ")
    .map((item) => item[0]?.toUpperCase() + item.substring(1))
    .join(" ");

export const getArrayOfUniqueObjectsByKey = (array, objKey) =>
  array.reduce((acc, curr) => {
    const repeated = acc.find((item) => item[objKey] === curr[objKey]);
    if (!repeated) {
      return acc.concat([curr]);
    }
    return acc;
  }, []);

/**
 * Validate cpf.
 */
const onlyNumbers = (value) => {
  if (!value) {
    return "";
  }

  return value.toString().replace(/\D+/g, "") || "0";
};

export const validate = (value) => {
  let sum = 0;
  let rest = 0;

  const strCPF = onlyNumbers(value);

  if (strCPF === "0") {
    return true;
  }

  if (
    strCPF === "00000000000" ||
    strCPF === "11111111111" ||
    strCPF === "99999999999"
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(strCPF.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;

  for (let c = 1; c <= 10; c += 1) {
    sum += parseInt(strCPF.substring(c - 1, c), 10) * (12 - c);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(strCPF.substring(10, 11), 10)) {
    return false;
  }

  return true;
};

export const validarCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};
export const Translate =  () => {
  const { t } = useTranslation("auth");
  return t("expired");
}
/*
 * Format backend error message
 */
export const formatErrorMessage = (
  res,
  genericMessage = "Desculpe, verifique os dados e tente novamente", tAuth = false
) => {

  if (res && res?.data?.message !== "Error occured.") {
    const { message, data, errors } = res.data;
    if(tAuth) {
      if(message == 'Unauthenticated.') {
        return tAuth;
      }
    }
    if (res?.data?.data || res?.data?.errors || message) {
      if (data && (data?.length > 0 || Object.keys(data)?.length > 0)) {
        const keys = Object.keys(data);
        const errs = keys.map((key) => data[key].join(", "));
        return errs.map((err) => <p key={err}>{err}</p>);
      }
      if (errors && (errors?.length > 0 || Object.keys(errors)?.length > 0)) {
        const keys = Object.keys(errors);
        const errs = keys.map((key) => errors[key].join(", "));
        return errs.map((err) => <p key={err}>{err}</p>);
      }
      if (message) {
        return message;
      }
    } else {
      return message;
    }
  }
  return genericMessage;
};

/**
 * Edited headers resulted.
 */
export const edited = (t, id, data, type, alert, route, render, onShow) => {
  const datad = {
    email: {
      data: data.email,
      error: t("edit_process.email.error"),
      success: t("edit_process.email.success"),
    },

    contact: {
      data: data.contact,
      error: t("edit_process.contact.error"),
      success: t("edit_process.contact.success"),
    },

    supplier_code: {
      data: data.supplier_code,
      error: t("edit_process.supplier_code.error"),
      success: t("edit_process.supplier_code.success"),
    },
  };

  const request = async (req) => {
    await req()
      .then(() => {
        render();
        onShow(false);
        alert.success(datad[type].success);
      })
      .catch(({ response }) =>
        alert.error(formatErrorMessage(response, datad[type].error))
      );
  };

  const email = () => {
    const submit = () =>
      api.post(`/${route}/${id}/headers`, { email: datad[type].data });

    request(submit);
  };

  const contact = () => {
    const submit = () =>
      api.post(`/${route}/${id}/headers`, { contact: datad[type].data });

    request(submit);
  };

  const supplier = () => {
    const submit = () =>
      api.post(`/${route}/${id}/headers`, { supplier_code: datad[type].data });

    request(submit);
  };

  switch (type) {
    case "email":
      email();
      break;

    case "contact":
      contact();
      break;

    case "supplier_code":
      supplier();
      break;

    default:
      break;
  }
};

export const parseISO = (time, errorMessage = "") => {
  try {
    return dateFnsParseISO(time);
  } catch {
    return errorMessage;
  }
};

export const getDiffDatesResponse = (checkDates) => {
  let diffDate = '';
  
  checkDates.forEach(item => {
    if(item.value > 1) {
      diffDate = {
        'isSingular': false,
        'value': item.value,
        'label': item.label
      }
      return;
    }
    if(item.value > 0) {
      diffDate =  {
        'isSungular': true,
        'value': item.value,
        'label': item.label
      }
      return;
    }
  });

  return diffDate;  
}

export const getDiffDates = (actualDate,compareDate) => {
  let differenceInSeconds = Math.floor((actualDate - compareDate) / 1000);
  let differenceInMinutes = Math.floor(differenceInSeconds / 60);
  let differenceInHours = Math.floor(differenceInMinutes / 60);
  let differenceInDays = Math.floor(differenceInHours / 24);

  const checkDates = [
    {
      'label': 'components.second',
      'value': differenceInSeconds,
    },
    {
      'label': 'components.minute',
      'value': differenceInMinutes,
    },
    {
      'label': 'components.hour',
      'value': differenceInHours,
    },
    {
      'label': 'components.day',
      'value': differenceInDays,
    },
  ];
  
  return getDiffDatesResponse(checkDates)
}

export const formatDocument = (document, personType) => {
  if (! document) {
    return null;
  }
  // PESSOA_FISICA_NACIONAL = 1;
  // PESSOA_JURIDICA_NACIONAL = 2;
  // PESSOA_FISICA_ESTRANGEIRA = 3;
  // PESSOA_JURIDICA_ESTRANGEIRA = 4;

  if (personType === 3 || personType === 4) {
    return document;
  }

  return masked(document);
};

export const buildAddress = ({
  place, street, number, complement, neighborhood, city, state, country_name, isEmpty,
} = { isEmpty: true }) => (isEmpty ? '' : [`${`${place} ` ?? ''} ${street ?? ''}`, number, complement, neighborhood, city, state, country_name].filter((i) => !! i).join(', ').trim());

export const mapAddress = (tooltipLabel, address) => {
  const text = buildAddress(address);
  return (
    <Tooltip label={tooltipLabel} width={250}>
      <P2 as="a" className="address" target="_blank" href={`https://www.google.com/maps/place/"${text}`} rel="noreferrer">{text}</P2>
    </Tooltip>
  );
};

export const isHTML = str => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}; 
