import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { Form } from "@unform/web";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { useMe } from "~/services";
import { useApi, useFetch } from "~/hooks";
import { P2, Modal, Button, SimpleInput } from "~/components";
import { Calendar } from 'react-feather';
import { colors } from "~/styles";

import { Container, ModalView, CheckboxWrapper, InfoText, ErrorWarning } from "./style";

const DuplicitySettings = () => {
  const formRef = useRef();
  const { gray } = colors;

  const api = useApi();
  const alert = useAlert();
  const { user, group } = useMe();
  const { t } = useTranslation("settings");

  const [isOpen, onOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [periodInDays, setPeriodInDays] = useState(1);
  const [isError,setIsError] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const clientId = group.value;
  const groupId = user.grupos[0]?.idgrupo;

  const type = !clientId ? "group" : "client";
  const id = type === "client" ? clientId : groupId;

  const {
    mutate,
    isValidating,
    data: duplicityOpenData,
  } = useFetch({
    url: `/settings/duplicity-open?${type}Id=${id}`,
  });

  useEffect(() => {
    if (!isEmpty(duplicityOpenData?.data) && !isChanged) {
      setPeriodInDays(duplicityOpenData?.data?.period_in_days);
    }

    if(isEmpty(duplicityOpenData?.data) && !isChanged) {
      setPeriodInDays(1);
    }
  }, [duplicityOpenData, isValidating]);

  const handleClick = async () => {
    await mutate();

    onOpen((prev) => !prev);
  };

  const handleEdit = async () => {
    setIsLoading(true);
    try {
      const payload = {
        type,
        period_in_days: periodInDays,
      };

      await api().put(`/settings/duplicity-open/${id}`, payload);

      await mutate();
      onOpen(false);
      alert.success(
        t("duplicityOpen.success")
      );
    } catch ({ response: { data } }) {
      alert.error(data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async () => {
    setIsLoading(true);
    try {
      const payload = {
        id,
        type,
        period_in_days: periodInDays,
      };

      await api().post(`/settings/duplicity-open`, payload);

      await mutate();
      alert.success(
        t("duplicityOpen.success")
      );
      onOpen(false);
    } catch ({ response: { data } }) {
      alert.error(data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if(periodInDays <= 0 || periodInDays >= 731) {
        setIsError(true);
        return;
      }
      if (!isEmpty(duplicityOpenData?.data)) {
          setIsChanged(true);
          setIsError(false);
          await handleEdit();
          return;
      }

      setIsChanged(true);
      setIsError(false);
      await handleCreate();
    } finally {
      setIsChanged(false);
    }
  };

  const handleChangePeriod = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === '' || re.test(e.target.value)) {      
       setPeriodInDays(e.target.value);
    }
}

  return (
    <Container>
      <CheckboxWrapper>
        <InfoText>{t("duplicityOpen.info_text")}</InfoText>
        <Button onClick={handleClick} label={t("duplicityOpen.button")} />
      </CheckboxWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal
          size="lg"
          hasActions
          show={isOpen}
          onShow={onOpen}
          showCancel={false}
          title={t("duplicityOpen.modal.title")}
          action={{
            loading: isLoading,
            label: t("duplicityOpen.modal.save_button"),
            onClick: () => formRef.current.submitForm(),
          }}
        >
          <ModalView>
            <div>
              <P2>{t("duplicityOpen.modal.description")}</P2>
              <ErrorWarning hasError={isError}>
                <span>{t("duplicityOpen.modal.waring")}</span>
              </ErrorWarning>
              <ErrorWarning hasError={isError}>
                <SimpleInput
                  icon={<Calendar color={gray._500}/>}
                  value={periodInDays}
                  onInput={handleChangePeriod}
                  appearance="secondary"
                />
              </ErrorWarning>
            </div>
          </ModalView>
        </Modal>
      </Form>
    </Container>
  );
};

export default DuplicitySettings;
